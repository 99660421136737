.footer {
  border-top: 2px solid #c9ab5d;

  width: 100%;
  background-color: #132f2e;
  margin-top: 50px;
}
.footer_logo {
  max-width: 200px;
}
.footer_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  max-width: 1100px;
  margin: 0 auto;
}
.adaptive_text {
  display: none;
}
.footer_info {
  max-width: 355px;
}
.button_wrapper {
  max-width: 300px;
  text-align: center;
}
.adaptive_contacts_wrapper {
  display: none;
}
/* Contact */
.contact {
  display: flex;
  align-items: center;
}
.contact_img_wrapper {
  width: 20px;
  height: 27px;
  display: flex;
  align-items: center;
}
.text {
  color: #fff;
  cursor: pointer;
  margin: 10px 0 10px 10px;
  transition: 0.1s linear;
  text-decoration: none;
}

/* ******************************** */
.footer_nav {
  min-width: unset !important;
}
/* footerNav */
.footer_nav_link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-right: 15px;
  transition: 0.1s linear;
}
.footer_nav_link:hover,
.text:hover {
  cursor: pointer;
  color: #c9ab5d;
}
/* ******************************** */

@media screen and (max-width: 1024px) {
  .footer {
    margin-top: 20px;
  }
  .adaptive_text {
    display: block;
    text-decoration: none;
    margin-top: 10px;
    color: #dbb933;
  }
  .text {
    display: none;
  }
  .footer_nav {
    display: none;
  }
  .footer_inner {
    display: block;
    padding: 20px 10px;
  }
  .footer_info {
    max-width: none;
  }
  .footer_adaptive {
    display: flex;
    justify-content: space-between;
  }
  .adaptive_contacts_wrapper {
    display: block;
    display: flex;
    max-width: 120px;
    width: 100%;
    justify-content: space-between;
  }
  .contacts_wrapper {
    display: none;
  }
}
