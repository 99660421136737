.container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clipboard {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  color: #000;
}
.clipboard span {
  margin-right: 5px;
}
.clipboard button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
}
.container {
  display: flex;
  width: 100%;
  border: 2px solid #c9ab5d;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #132f2e;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
@media only screen and (max-width: 768px) {
  .main_title_wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: unset !important;
  }
  .first {
    display: none !important;
  }
  .second {
    width: 100% !important;
  }
}
.first {
  width: 33%;
  display: flex;
  justify-content: center;
}
.first img {
  max-width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.second {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inner {
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}
.inner_progress {
  margin: 0px 15px 0px 15px;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 6px;
  background-color: #183b3a;
  color: #fff;
}

.package-container {
  display: flex;
  flex-wrap: wrap;
}

.package {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: 10px;
  border: 2px solid #d2a800;
  border-radius: 10px;
  text-align: center;
}

.package img {
  width: 100%;
  border-radius: 10px;
}

.package h3 {
  margin-top: 10px;
  font-size: 16px;
}

.package p {
  margin: 5px 0;
  font-size: 14px;
}

.package .price {
  font-weight: bold;
}
.package button {
  margin-top: auto;
  width: 100%;
  padding: 8px 0;
  background-color: #c9ab5d;
  color: #fff;
  font-weight: bold;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.package button:hover {
  background-color: #c9ab5d;
}

.package button:disabled {
  background-color: #c9ab5d;
  cursor: not-allowed;
}

.unavailable {
  color: red;
}
