.mobile_wallet_wrapper{
	display:flex;
	width:calc(100% - 60px);
	padding:10px;
	justify-content: center;
	align-content: center;
	background-color: #132f2e;
	border-bottom: 2px solid #c9ab5d;
	border-left: 2px solid #c9ab5d;
	border-right: 2px solid #c9ab5d;
	border-radius:0px 0px 14px 14px;
	margin: 0 auto;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.mobile_wallet{
	display:none;
}
@media only screen and (max-width: 768px) {
  .mobile_wallet {
    display: block;
  }
}