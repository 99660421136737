.block {
  max-width: 1344px;
  min-height: 75vh;
  margin: 0 auto;
}
.title {
  color: #fdd536;
  font-size: 2rem;
}
.message {
  color: #fff;
  font-size: 1.4rem;
}

@media screen and (max-width: 1024px) {
  .block {
    padding: 0 10px;
    background-image: none;
    margin: 0;
  }
}