.invalid {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #ff0000, #ff0000) border-box;
}
.error {
  color: #ff0000;
}
.valid {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #fdd536, #fdd53648) border-box;
}
.input {
  margin-bottom: 15px;
  border: 2px solid transparent;
  border-radius: 15px;
  display: inline-block;
  margin-top: 0px;
  background-color: transparent;
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  max-width: 500px;
  outline: none;
  color: #fdd536;
  z-index: 1;
  transition: 0.1s linear;
}

.edit_wrapper {
  max-width: 500px;
    margin: 0 auto;
    background-color: #132f2e;
    border-radius: 15px;
    border: 2px solid #c9ab5d;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-top: 60px;
    margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .edit_wrapper{
    margin-right:20px;
    margin-left:20px;
  }
}
.edit_item {
  max-width: 100%;
  margin: 5px 0;
}

.edit_input {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #fdd53648) border-box;
  border: 2px solid transparent;
  border-radius: 15px;
  display: inline-block;
  margin-top: 0px;
  background-color: transparent;
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  max-width: calc(100% - 44px);
  outline: none;
  color: #c9ab5d;
  z-index: 1;
  transition: 0.1s linear;
  margin-bottom: 15px;
}

.edit_inputt::placeholder {
  color: #c9ab5d;
}
.edit_input:hover,
.edit_input:focus {
  box-shadow: 1px 1px 5px #c9ab5d;
  cursor: pointer;
}
.auth_input {
  width: 300px;
  margin: 0 7.5px;
}

.update_button {
  border: none;
  min-width: 100%;
  background-color: #c9ab5d;
  font-weight: bold;
  font-size: 1rem;
  color: #000;
  padding: 10px 40px;
  border-radius: 10px;
  transition: 0.1s linear;
}
.update_button:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px #c9ab5d;
}
@media screen and (max-width: 320px) {
  .button_wrapper {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 425px) and (min-width: 321px) {
  .adaptive_wrapper {
    display: flex;
    justify-content: center;
  }
  .button_wrapper {
    display: flex;
    justify-content: center;
  }
}
