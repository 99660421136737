.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.138);
  animation-name: appear;
  animation-duration: 300ms;
}
.hidden_modal {
  display: none;
}
.modal_dialog {
  /* border-radius: 22px; */
  width: 100%;
  padding: 5px 10px;
  max-width: 550px;
  background: #c9ab5d;
  border: 2px dashed #000;
  position: relative;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  height: 120px;
}
.modal_text {
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0;
  cursor: pointer;
}
.exit {
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
.btn_wrapper {
  display: flex;
  justify-content: center;
}
.close_btn {
  font-size: 1.5rem;
  background: transparent;
  border: none;
}
.close_btn:hover {
  cursor: pointer;
}
.see_packages_btn {
  border-radius: 44px;
  border: 2px solid #1e1e1e;
  padding: 10px 15px;
  color: #c9ab5d;
  background-color: #1e1e1e;
}
.see_packages_btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px #1e1e1e8a;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}
@media screen and (max-width: 769px) {
  .modal_dialog {
    width: 280px;
  }
}
