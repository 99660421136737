.admin_panel_wrapper{
    max-width: 1344px;
    margin: 0 auto;
    margin-bottom: 30vh;
    min-height: 100vh;
}
.admin_control_panel{
    max-width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;
    margin-top: 70px;
}
.item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}