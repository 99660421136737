.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.tree {
  margin: 0 auto;
  border: 2px solid #c9ab5d;
  border-radius: 15px;
  background-color: #132f2e;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  color: #c9ab5d;
}
.active {
  color: #c9ab5d;
}
.inactive {
  color: #f00;
}