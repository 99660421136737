.registration_container {
  max-width: 500px;
  margin: 0 auto;
    background-color: #132f2e;
    border-radius: 15px;
    border: 2px solid #c9ab5d;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-top:60px;
    margin-bottom:60px;
}
@media only screen and (max-width: 768px) {
    .registration_container{
      margin-right:20px;
      margin-left:20px;
    }
    .input {
      max-width:calc(100% - 44px) !important;
    }

}
.auth_link {
  text-decoration: none;
  color: #c9ab5d85;
  transition: 0.1s linear;
}
.auth_link:hover {
  text-decoration: none;
  color: #c9ab5d;
  transition: 0.1s linear;
  cursor: pointer;
}
.reg_button {
  border: none;
  background-color: #c9ab5d;
  padding: 10px 20px;
  border-radius: 44px;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  transition: 0.1s linear;
}
.reg_button:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px #c9ab5da0;
}
.submit_error {
  color: #fe0000;
  margin-bottom: 20px;
  font-weight: 700;
}

/* Agreemet */
.agreement {
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
  color: #fff;
}
.term_btn_wrapper {
  display: flex;
  justify-content: center;
}
.termBtn {
  border-radius: 44px;
  border: 2px solid #c9ab5d;
  padding: 10px 15px;
  min-width: 150px;
  font-weight: bold;
  background-color: transparent;
  color: #c9ab5d;
  font-size: 1.2rem;
  transition: 0.1s linear;
  text-align: center;
  text-decoration: none;
}
.termBtn:hover {
  box-shadow: 2px 2px 2px #c9ab5daf;
  cursor: pointer;
}
/* ******** */
/* checl box */
.checkBox {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.checkBox_input {
  display: none;
}
.fake_accept {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #c9ab5d;
  border-radius: 2px;
  position: relative;
}
.checkBox:hover {
  cursor: pointer;
}
.fake_accept::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url(./img/checkMark.svg);
  transition: 0.1s linear;
  opacity: 0;
}
.checkBox_input:checked + .fake_accept::before {
  opacity: 1;
}
.agree_link {
  color: #c9ab5d;
  margin-left: 20px;
}
/* ******************* */
.invalid {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #ff0000, #ff0000) border-box;
}
.error {
  color: #ff0000;
}
.valid {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
}
.input {
  margin-bottom: 15px;
  border: 2px solid transparent;
  border-radius: 15px;
  display: inline-block;
  margin-top: 0px;
  background-color: transparent;
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  max-width: 444px;
  outline: none;
  color: #c9ab5d;
  z-index: 1;
  transition: 0.1s linear;
}
.input:hover,
.input:focus {
  box-shadow: 1px 1px 5px #c9ab5d82;
  cursor: pointer;
}
.input::placeholder {
  color: #c9ab5d7b;
}
.registration_active_section {
  max-width: 500px;
}
.button_container {
  text-align: center;
  max-width: 500px;
}
.text {
  color: #fff;
  font-size: 1.2rem;
  margin: top 10px;
  margin-bottom: 30px;
}
.title {
  color: #c9ab5d;
}

.select_core {
  display: none;
}
.select_header {
  display: flex;
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  font-weight: 700;
  border: 2px solid transparent;
  border-radius: 20px;
  max-width: 500px;
  cursor: pointer;
  margin: 0 auto;
  padding: 15px 20px;
  z-index: 1;
}
.select_header_active {
  display: flex;
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  font-weight: 700;
  border: 2px solid transparent;
  border-radius: 20px;
  max-width: 500px;
  cursor: pointer;
  margin: 0 auto;
  padding: 15px 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select {
  position: relative;
  max-width: 500px;
  margin: 15px 0;
  user-select: none;
  z-index: 1;
}
.select_body_visible::-webkit-scrollbar {
  display: none;
}
.select_current {
  font-size: 1.1rem;
  color: #c9ab5d;
}
.select_icon_container {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  color: #c9ab5d;
  width: 21px;
  height: 21px;
}
.select_icon {
  border-top: 6px solid #c9ab5d;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.select_icon_active {
  display: none;
}
.select_body {
  overflow: scroll;
  position: absolute;
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  border: 2px solid transparent;
  border-top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  top: 100%;
  display: none;
  z-index: 1;
}

.select_body_visible,
.select_coin_body_visible {
  display: block;
  z-index: 999;
  min-height: 200px;
  overflow: scroll;
  position: absolute;
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  border: 2px solid transparent;
  border-top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  top: 100%;
}
.select_coin_body_visible {
  display: flex;
  align-items: center;
}
.select_body_visible {
  max-height: 335px;
}
.select_coin_body_visible {
  max-height: 222px;
}
.select_item {
  line-height: 24px;
  padding: 15px 20px;
  font-size: 1.1rem;
  cursor: pointer;
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  border: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  width: 100%;
  color: #c9ab5d;
  font-weight: 700;
  transition: 0.1s linear;
}
.select_item:first-child {
  border-top: 0;
}
.select_item:last-child {
  border-bottom: 0;
}
.select_item:hover {
  background: linear-gradient(#36393d 0 0) border-box;
}

@media screen and (max-width: 320px) {
  .input {
    max-width: 250px;
  }
  .select_header,
  .select_header_active {
    width: 250px;
    margin: 0;
  }
  .select {
    width: 294px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 321px) {
  .select_header,
  .select_header_active {
    width: 300px;
    margin: 0;
  }
  .select {
    width: 344px;
  }
}
@media screen and (max-width: 1024px) {

  .auth_link {
    text-decoration: none;
    color: #c9ab5d;
  }
  .registration_active_section {
    max-width: 100%;
  }
  .text {
    color: #fff;
    font-size: 1.2rem;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1344px) and (min-width: 321px) {
  .select {
    z-index: 2;
  }
  .select_item {
    z-index: 2;
  }
}
