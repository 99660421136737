.transactions_wrapper {
  max-width: 1344px;
  margin: 0 auto;
  margin-bottom: 30vh;
  min-height: 100vh;
}
.transactions_nav_link {
  color: rgb(201, 171, 93);
  margin-right: 20px;
  transition: 0.1s linear;
}
.transactions_nav_link:hover {
  color: #fbd439;
}

/* transactions table  */

/* table header */
.transactions_table_container {
  max-width: 1344px;
  margin: 0 auto;
  margin-top: 30px;
}
.transactions_table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
thead th {
  padding: 5px;
  border-bottom: 2px solid #fbd539;
  vertical-align: bottom;
  color: #000 !important;
  background-color: rgb(201, 171, 93);
}

td {
  padding: 5px;
  color: #fff;
}
td a {
  color: #fbd539;
  font-weight: 700;
}
tbody td {
  text-align: center;
}
tbody tr {
  border-bottom: 2px solid #fbd43972;
}
tbody tr:last-child {
  border-bottom: none;
}

.formContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputField {
  padding: 8px 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.submitButton {
  padding: 8px 16px;
  background-color: rgb(201, 171, 93);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: rgb(201, 171, 93);
}
