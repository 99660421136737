.about{
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 100px;
}
.title{
    color: #c9ab5d;
    font-size: 2rem;
}
.sub_title{
    color: #fff;
    font-size: 1.4rem;
}
.snake_wrapper{
    flex-direction: column;
    display: flex;
    justify-content: center;
}
/* AboutInfo */
.about_info{
    max-width: 800px;
    margin: 0 auto;
}
/* *********************************** */
/* Platforms */
.platform{
    margin-top: 50px;
    max-width: 800px;
}
.platforms_img{
    max-width: 441px;
    max-height: 367px;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(20deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(20deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(20deg); 
        transform:rotate(20deg); 
    } 
}
.platform_wrapper{
    display: flex;
}
.sanke_img{
    max-width: 800px;
    display: block;
    -webkit-animation:spinSnake 15s linear infinite;
    -moz-animation:spinSnake 15s linear infinite;
    animation:spinSnake 15s linear infinite;
}
@-moz-keyframes spinSnake { 
    100% { -moz-transform: translateX(20px);  } 
}
@-webkit-keyframes spinSnake { 
    100% { -webkit-transform: translateX(20px); } 
}
@keyframes spinSnake { 
    100% { 
        -webkit-transform: translateX(20px); 
        transform:translateX(20px); 
    } 
}
.text{
    color: #fff;
    font-size: 1.1rem;
    margin-left: 20px;
}
.text_wrapper{
    display: flex;
    margin-left: 20px;
}

/* Rules */
.rules_main_container{
    background-image: url(./img/galaxy.svg);
    background-repeat: no-repeat;
    background-position: right;
}
.rules_main_text{
    max-width: 600px;
}
.rules_subscription_wrapper{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.circle{
    border-radius: 50%;
    box-shadow: 1px 1px 5px #c9ab5d48;
    padding: 20px;
    max-width: 100px;
    max-height: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle_img{
    max-width: 40px;
}
.rules_text,.rules_circle_text{
    color: #fff;
    font-size: 1.1rem;
}
.rules_circle_text{
    margin-left: 20px;
}
.snake_mobile{
   
    display: none;
}

@media screen and (max-width:320px){
    .snake_wrapper{
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .sanke_img{
        display: none;
    }
    .snake_mobile{
        display: block;
    }
    .about{
       padding: 0 20px;
        background-image: none;
      margin: 0;
    }
    .platforms_img{
        display: none;
    }
    .sanke_img{
        max-width: 360px;
    }
    .rules_main_container{
        background-image: none;
    }
    .sub_title{
      margin: 0;
    }
    .text_wrapper{
        display: flex;
        margin-left: 0;
    }
    .text{
        margin-left: 10px;
       margin-bottom: 15px;
    }
    .rules_subscription_wrapper{
       
        margin-top: 0;
    }
    .circle{
        border-radius: 50%;
        box-shadow: 2px 2px 10px #c9ab5d48;
        padding: 20px;
        max-width: 100px;
        max-height: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #c9ab5d;
    }
}