.container{
    max-width: 800px;
    margin: 0 auto;
    color: #c9ab5d;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.div_img{
    height: 100px;
    position: absolute;
}
.option_wrapper{
   position: relative;
   max-width: 480px;
   margin: 0 auto;
}
.option_popup{
    border-radius: 5px;
    background-color: #fbfbfb35;
    border: 1px solid #c9ab5d;
    margin: 5px 0;
    font-size: 14px;
}
.option_text{
    font-weight: 700;
}
.popup_text,.option_text{
    margin: 0;
    color: #fff;
}
.option_container{
    position: relative;
    height: 100px;
    max-width: 478px;
    display: flex;
    align-items: center;
}
.option{
    margin-top: 30px;
}
.bonus_label{
    color: red !important;
    text-align: center;
    margin-bottom: 10px;
}
.option_img{
    max-height:100px;
    margin-left: 10px;
}

.button_sign{
    color: #FBD539;
    font-weight: 700;
    margin-left: 10px;
}
.sharp_button{
  background-image: url(./img/sharpButton.svg);
  width: 149px;
  display: flex;
  position: absolute;
  top: 25%;
 right: 0%;
align-items: center;
height: 39px;
background-size: contain;
}
.sharp_button:hover{
    cursor: pointer;
}
.active_wrapper{
    max-width: 800px;
    margin: 0 auto;
}
.date_text{
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0;
}
@media screen and (max-width:1024px) {
    .container{
        max-width: 1344px;
        margin: 0 auto;
        color: #c9ab5d;
        background-size: none;
        background:none;
        background-position: none;
        background-repeat: no-repeat;
    }
    .div_img{
        height: 85px;
    }
    
    .title{
        margin-left: 10px;
    }
    .option_wrapper{
        z-index: -1;
     }
}
@media screen and (max-width:320px){
    .sharp_button{
        right: 0%;
        width: 115px;
        height: 30px;
        top: 30%;
    }
    .date_text{
        margin: 0;
    }
    .button_sign{
        font-size: .7rem;
    }
    .option_text{
        font-size: .7rem;
    }
    .option_img{
        width: 60px;
    }
    .option_text:first-child{
        margin-top: 10px;
    }
    .option_popup{
        margin-bottom: 10px;
        padding: 1px;
    }
    .popup_text{
        font-size: .7rem;
    }
    .container{
        padding: 0 10px;
    }
    p{
        margin-top: 20px;
        margin-bottom: 0;
    }
    .div_img{
        max-width: 300px;
    }
}
@media screen and (min-width:321px) and (max-width:375px){
    .div_img{
        max-width: 320px;
    }
    .button_sign{
        font-size: .7rem;
    }
    .date_text{
        margin: 0;
    }
    p{
        margin: 0;
    }
    .option_img{
        width: 60px;
    }
    .option_text{
        font-size: .7rem;
    }
    .option_text:first-child{
        margin-top: 10px;
    }
    .option_popup{
        margin-bottom: 10px;
    }
    .container{
        padding: 0 10px;
    }
    .sharp_button{
        right: 0%;
        top: 30%;
        width: 115px;
        height: 30px;
    }
}
@media screen and (max-width:1343px){
    .container{
        padding: 0 10px;
    }
}