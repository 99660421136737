.main_block {
  max-width: 800px;
  margin: 0 auto;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  min-height: 80vh;
}

.container_data {
  margin-top: 40px;
}

@media screen and (max-width: 1024px) {
  .main_block {
    padding: 0 10px;
  }
}

th{
    color:#fff !important;
    border-bottom:none !important;
}
tbody{
    background-color: #183b3a !important;
}
td{
    color:#fff !important;
    border-bottom: 1px solid #132f2e !important;
}
table{
    
}
tbody tr {
    border-bottom: 1px solid #183b3a !important;
}
.tablewrapper{
    border: 2px solid #c9ab5d;
    border-radius: 15px;
    background-color: #132f2e !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}