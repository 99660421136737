.disconnect_button {
  background-color: #d0032a;
  transition: all .6s;
}
.disconnect_button:hover{
  background-color: #740016;
}

.connect_button {
  background-color: #183b3a;
  transition: all .6s;
}
.connect_button:hover {
  background-color: #081d1c;
}

.wallet {
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.header_nav button {
  border: none;
  border-radius: 5px;
  color: white;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
}

.login_block {
  color: #c9ab5d;
  position: relative;
  padding-bottom: 10px;
  padding-top: 5px;
}

.login_block::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #c9ab5d;
}

.header {
  transition: 0.3s ease-in-out;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 899;
  background-color: #132f2e;
  border-bottom: 2px solid #c9ab5d;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    nav {
    display: flex !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    nav {
    display: flex !important;
  }
}

.header_logo {
  max-width: 200px;
}

.header_inner {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.1s ease-in;
  height: 60px;
}
.header_active {
  background-color: #132f2e;
  transition: 0.1s ease-in;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 899;
  border-bottom: 2px solid #c9ab5d;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
nav {
  min-width: 200px;
  display: flex;
}
.header_nav_link {
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
  transition: 0.1s;
  text-decoration: none;
}
.header_nav_link:hover {
  cursor: pointer;
  color: #c9ab5d;
}
.crypto_img {
  margin: 0 15px;
  max-width: 36px;
  cursor: pointer;
}
.languageName {
  color: #fff;
  cursor: pointer;
  margin: 0 10px;
  transition: 0.1s;
  background: transparent;
  border: none;
}
.languageName:hover {
  cursor: pointer;
  color: #c9ab5d;
}
.languageName {
  margin: 0;
}
.button_container {
  max-width: 220px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tip {
  transition-duration: 0s;
}
.adaptive_header_menu_container {
  display: none;
}
.user_header_account_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}
.user_img {
  width: 36px;
  height: 36px;
  margin: auto 0;
  border-radius: 50%;
  object-fit: cover;
  transition: all .6s;
}
.user_img:hover{
  opacity:.6;
}
.user_name {
  color: #c9ab5d;
  max-width: 85px;
  overflow-x: hidden;
}
.user_header_burgerMenu_sm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.user_burgerMenu_item {
  background-color: #fff;
  margin: 3px 0;
  border: 2px solid #fff;
  min-width: 30px;
  border-radius: 20px;
  cursor: pointer;
}
.burger_menu {
  cursor: pointer;
  max-width: 35px;
}
.dropDown_menu {
  width: 170px;
  position: absolute;
  top: 120%;
  color: #fff;
  right: 0%;
  background-color: black;
  border: 1px solid #c9ab5d;
  padding: 0;
  transition: 0.2s ease-in-out;
  opacity: 0;
  cursor: pointer;
  user-select: none;
  display: none;
}
.hidden_menu::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.hidden_menu {
  width: 170px;
  position: absolute;
  top: 120%;
  color: #fff;
  right: 0%;
  background-color: #132f2e;
  border: 2px solid #c9ab5d;
  padding: 0;
  z-index: 899;
  transition: 0.2s ease-in-out;
  opacity: 1;
  display: block;
  cursor: default;
  border-radius: 15px;
  max-height: 360px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.dropDown_item {
  list-style: none;
  border-top: 1px solid #c9ab5d;
  padding: 10px 15px;
  transition: 0.1s linear;
  z-index: 999;
}
.dropDown_item_wrap:first-child > .dropDown_item:hover {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: #183b3a;
}
.dropDown_item_wrap {
  list-style: none;
  text-decoration: none;
}
.dropDown_item:hover {
  background-color: #183b3a;
}
.dropDown_item_wrap:last-child > .dropDown_item:hover {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background-color: #183b3a;
}
.menu_link {
  text-decoration: none;
  color: #fff;
  transition: 0.1s linear;
  margin: 0;
}
.menu_link:hover {
  color: #c9ab5d;
}
.menu_link svg{
  margin-right:15px;
  width:20px;
}
.profile_link {
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  width: 160px;
}
.dropDown_item:first-child {
  border-top: none;
}
.dropDown_item:hover {
  color: #c9ab5d;
  cursor: pointer;
}
.toolTip_wrapper {
  display: flex;
  flex-direction: column;
}
.header_cripto_tips {
  display: flex;
}

/* tooltip */
.tooltip_btc {
  display: none;
}
.tooltip_btc_active {
  display: flex;
  border: 2px solid #c9ab5d;
  align-items: center;
  border-radius: 40px;
  box-shadow: 1px 1px 2px #c9ab5d80;
  padding: 10px 15px;
  position: absolute;
  top: 110%;
  color: #fff;
  background-color: #000;
}
.tooltip_eth {
  display: none;
}
.tooltip_eth_active {
  border: 2px solid #c9ab5d;

  display: flex;
  align-items: center;
  border-radius: 40px;
  box-shadow: 1px 1px 2px #c9ab5d80;
  padding: 10px 15px;
  position: absolute;
  top: 110%;
  color: #fff;
  background-color: #000;
}
.tooltip_bnb {
  display: none;
}
.tooltip_bnb_active {
  border: 2px solid #c9ab5d;

  display: flex;
  align-items: center;
  border-radius: 40px;
  box-shadow: 1px 1px 2px #c9ab5d80;
  padding: 10px 15px;
  position: absolute;
  top: 110%;
  color: #fff;
  background-color: #000;
}
.sum {
  color: #fff;
}
.img {
  margin: 0 5px;
}
.percent_green {
  color: #299e00;
}
.percent_red {
  color: #9e0000;
}
.authorized_wrapper {
  display: flex;
  align-items: center;
}
.profile_link_img {
  display: none;
}

/* адаптив */
@media screen and (max-width: 320px) {
  .adaptive_header_menu_container {
    display: block;
  }
  .profile_link_img {
    display: block;
    margin-right: 10px;
  }
  .lang_wrapper {
    display: flex;
    margin-right: 5px;
    min-width: 100px;
  }
  nav {
    display: none;
  }
  .languageName {
    width: 10px;
    margin: 10px;
  }
  .header {
    border-bottom: 2px solid #c9ab5d;
    z-index: 899;
    margin-bottom: 20px;
  }
  .header_active {
    z-index: 899;
  }
  .header_inner {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .user_header_account_container {
    display: block;
    width: 100%;
  }
  .header_nav_link {
    display: none;
  }

  .crypto_img {
    display: none;
  }

  .profile_link {
    display: none;
  }
  .button_container {
    display: none;
  }
  .user_header_burgerMenu_sm {
    z-index: 899;
  }
}

.profile_link_img {
    transition: all .6s !important;
    opacity: 1 !important;
  }
  .profile_link_img:hover{
    opacity:.6 !important;
  }

@media screen and (max-width: 1220px) and (min-width: 321px) {
  nav {
    display: none;
  }
  .adaptive_header_menu_container {
    display: block;
  }
  .profile_link_img {
    display: block;
    margin-right: 10px;
    transition: all .6s;
    opacity: 1;
    height:36px;
    width:36px;
  }
  .profile_link_img:hover{
    opacity:.6;
  }
  .languageName {
    margin: 0;
  }
  .header {
    border-bottom: 2px solid #c9ab5d;
    z-index: 899;
  }
  .header_active {
    z-index: 899;
  }
  .header_inner {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .user_header_account_container {
    display: block;
    width: 100%;
  }
  .header_nav_link {
    display: none;
  }

  .crypto_img {
    display: none;
  }
  .profile_link {
    display: none;
  }
  .button_container {
    display: none;
  }
  .user_header_burgerMenu_sm {
    z-index: 9999;
  }
}
