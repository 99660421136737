.button {
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 40px;
  color: #000;
  transition: 0.1s linear;
}
.button:hover {
  box-shadow: 1px 1px 2px #c9ab5d;
  cursor: pointer;
}
.button svg {
  margin-right: 10px;
}
