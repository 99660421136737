.package_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.package {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  margin: 10px;
  border: 2px solid #c9ab5d;
  border-radius: 15px;
  text-align: center;
  background-color: #132f2e;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
@media only screen and (max-width: 768px) {
  .package {
    width: 100%;
  }
}
.package[visibility="unavailable"] {
  opacity: 0.6;
  cursor: not-allowed;
  border-color: #d0032a !important;
}

.package[visibility="unavailable"] img {
  border-bottom: 2px solid #d0032a;
}

.package img {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  border-bottom: 2px solid #c9ab5d;
}

.package h3 {
  font-size: 16px;
}

.package p {
  margin: 5px 0;
  font-size: 14px;
}

.package .price {
  font-weight: bold;
}
.package button {
  margin-top: auto;
  width: 100%;
  padding: 15px 0;
  background-color: #c9ab5d;
  color: #fff;
  font-weight: bold;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  text-transform: uppercase;
}
button.unavailable {
  background-color: #d0032a !important;
}
.package button:hover {
  background-color: #937a39;
}

.package button:disabled {
  background-color: #c9ab5d;
  cursor: not-allowed;
}

.unavailable {
  color: red;
}

.package h3 {
  color: #fff;
  font-size: 18px;
  margin: 0px;
}

.packagePrice {
  font-size: 28px !important;
  color: #fff;
  font-weight: 700;
}

.packageInfo {
  padding: 20px;
}
