.authorization {
  display: flex;
  background-color: #132f2e;
  border-radius: 15px;
  border: 2px solid #c9ab5d;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.auth_link {
  text-decoration: none;
  color: #c9ab5d85;
  transition: 0.1s linear;
}
.auth_link:hover {
  text-decoration: none;
  color: #c9ab5d;
  transition: 0.1s linear;
  cursor: pointer;
}
.authorization_inner {
  max-width: 500px;
}
.button_wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
}
.login_btn {
  width: 150px;
  border: none;
  background-color: #c9ab5d;
  padding: 10px 20px;
  border-radius: 44px;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  transition: 0.1s linear;
}

.login_btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px #c9ab5da0;
}
.auth_input {
  background: linear-gradient(#1d2229 0 0) padding-box,
    linear-gradient(to right, #c9ab5d, #c9ab5d48) border-box;
  border: 2px solid transparent;
  border-radius: 15px;
  display: inline-block;
  margin-top: 0px;
  background-color: transparent;
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  max-width: 444px;
  outline: none;
  color: #c9ab5d;
  z-index: 1;
  transition: 0.1s linear;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .auth_input {
    max-width: calc(100% - 44px) !important;
  }
}

.auth_input::placeholder {
  color: #c9ab5db8;
}
.auth_input:hover,
.auth_input:focus {
  box-shadow: 1px 1px 5px #c9ab5d82;
  cursor: pointer;
}
.input::placeholder {
  color: #c9ab5d7b;
}
.adaptive_wrapper {
  max-width: 600px;
  margin: 0 auto;
  min-height: 80vh;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
