.slick-active {
  opacity: 0.5;
  transition: 0.1s linear;
  transform: scale(0.75);
}
.slick-list {
  max-height: 350px;
}

.slick-current {
  opacity: 1 !important;
  transition: 0.1s linear;
  transform: scale(1) !important;
}
.slick-slide {
  opacity: 0.5;
  transform: scale(0.75);
}
