.text{
    margin: 0 10px;  
    font-size: 1rem;
    font-weight: 700;
}
.components_container{
    max-width: 800px;
    margin: 0 auto;
}
.MuiPaper-root{
    background-color: rgba(0,0,0,0) !important;
}
th{
    color:#fff !important;
    border-bottom:none !important;
}
tbody{
    background-color: #183b3a !important;
}
td{
    color:#fff !important;
    border-bottom: 1px solid #132f2e !important;
}
table{
    
}
tbody tr {
    border-bottom: 1px solid #183b3a !important;
}
.tablewrapper{
    border: 2px solid #c9ab5d;
    border-radius: 15px;
    background-color: #132f2e;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.container{
    max-width: 800px;
    margin: 0 auto;
    background-size: contain;
    min-height: 100vh;
}
.transfer_btn{
    border: none;
    background: transparent;
    font-size: 1rem;
    font-weight: bold;
    color: #FBD539;
    transition: .1s linear;
}
.transfer_btn:hover{
    cursor: pointer;
}
.graphic_total_text{
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 20%;
    width: 99px;
    text-align: center;
    word-break: break-all;
}
.graphic_comp{
    position: relative;

}
@-moz-keyframes graphicSpin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes graphicSpin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes graphicSpin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
progress{
    background-color: #fbd43948;
    -webkit-appearance: none;
    accent-color: #fbd539;
    border-radius: 44px;
    height: 20px;
    width: 700px;
}
progress::-webkit-progress-bar{
    background-color: #fbd43948;
    border-radius: 44px;

}
progress::-webkit-progress-value{
    background-color: #fbd539;
    border-radius: 44px;

}
progress::-moz-progress-bar{
    background-color: #fbd539;
    border-radius: 44px;

}
.progress_wrapper{
    max-width: 800px;
    margin: 0 auto;
}
.progress_text{
    color: rgba(255, 255, 255, 0.736);
    font-size: 1rem;
    font-weight: 700;
}
.progress_active_text{
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
}
/* Graphic Display */
.graphic_display{
    transition: .3s linear all;
    -webkit-transition: .3 linear all;
    width: 200px;
    min-height: 60px;
    border-radius: 44px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    text-align: center;
    margin: 40px 0;
}
.graphic_display:hover{
    transform: scale(1.1);
}
.graphic_display_text{
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
}
.graphic_display_sub_text{
    font-size: .8rem;
    margin: 0 auto;
    max-width: 150px;
text-align: center;
}
/* graphic left */
.graphic{
    max-width: 840px;
    margin: 0 auto;
    margin-top: 100px;
    justify-content: space-around;
    display: flex;
   
}

.graphic_left{
    max-width: 400px;
}
.graphic_left_inner{
    display: flex;
    max-width: 400px;
    align-items: center;
}
.graphic_img{
    max-width: 200px;
}
.graphic_title{
    margin: 0 10px;  
    color: #fff;
    font-size: 1rem;
    text-align: center;
}
/* *************** */
/* Slider */

/* Rocket Section */
.rockets_wrapper{
    height: 500px;
}
.rocket_container{
    margin: 0 auto;
    margin-top: 30px;
    max-width: 800px;
    display: flex;
    align-items: center;
    height: 155px;
}
.rocket_img{
    max-width: 800px;
    position: absolute;
    top: 0;
}
.rocket_wrapper{
    position: relative;
    height: 154px;
    margin: 0;
}
.rocket_icon{
    max-height:150px;
    margin-left: 10px;
}
.rocket_text_container{
    max-width: 350px;
    margin-left: 20px;
}
.rocket_text{
    font-size: 1.1rem;
    color: #fff;
    font-weight: 700;
    margin: 0;
}
.rocket_sub_text{
    color: #fff;
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: 700;
}
/* *** */
.sharp_btn{
    background: url(./img/sharpBtn.svg); 
    width: 287px;
    height: 68px;
    display: flex;
    position: absolute;
    top: 30%;
   right: 20%;
  align-items: center;
  background-size: contain;

}
.sharp_btn:hover{
    cursor: pointer;
}
.button_sign{
    color: #FBD539;
    font-weight: 700;
    margin-left: 20px;
}
/* Info */
.info_wrapper{
    max-width: 800px;
    margin: 0 auto;
    /* margin-top: 100px; */
    /* background: url(./img//cometa.svg); */
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}
.balance_info_item{
    display: flex;
    align-items: center;
    margin-top: 30px;
    
}

.info_circle{
    border: 1px solid #FBD539;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 10px #FBD539;
}
/*  */
.adaptive_rocket_wrapper{
display: none;
text-align: center;
}
.adaptive_btn{
    margin: 0 auto;
    border-radius: 44px;
    background-color: #FBD539;
    color: #000;
    padding: 10px 15px ;
    border: none;
}
.info_text_wrapper{
    max-width: 250px;
}
.adaptive_btn{
    color: #000;
    font-weight: 700;
}

.graphic_left_inner{
    display: flex;
    justify-content: center;
}
.info_wrapper{
    margin-top: 100px;
}
@media screen and (max-width:836px) {
    .container{
        background: none;
        padding: 0 10px;
    }
}
@media screen and (max-width:320px){
    progress{
        width: 300px;
    }
    .graphic_display{
        width: 100px;
        border-radius: 44px;
    }
    .graphic{
        flex-direction: column;
    }
    .graphic_left_inner{
        justify-content: center;
    }
   
    .rockets_wrapper{
        display: none;
    }
    .adaptive_rocket_wrapper{
        display: block;
        border: 2px solid #fbd43994;
        margin: 15px;
        border-radius: 44px;
        padding: 10px 10px;
        }
        .rocket_icon{
            display: none;
        }
        .rocket_container{
            height: auto;
            margin: 0;
            margin-bottom: 10px;
        }
        .rocket_text_container{
            width: auto;
            margin: 0;
        }
        .info_circle{
            width: 80px;
            height: 80px;
        }
        .info_text_wrapper{
            max-width: 200px;
        }
        .balance_info_item{
            margin-top: 10px;
        }
        .info_wrapper{
            background: none;
        }
        .graphic{
            margin-top: 20px;
        }
        .container{
            padding: 0 10px;
        }
}
@media screen and (max-width:1024px) and (min-width:910px) {
    .sharp_btn{
        right: 10%;
    }
   
}
@media screen and (max-width:909px) and (min-width:769px){
    .sharp_btn{
        right: 0;
    }
}
@media screen and (max-width:1340px) and (min-width:1024px){
        .sharp_btn{
        right: -5;
    }
}
@media screen and (max-width:768px) and (min-width:425px) {
    .graphic{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    progress{
        width: 400px;
    }
    .graphic_left_inner{
        justify-content: center;
    }
    .container{
        padding: 0 10px;
    }
    .rockets_wrapper{
        display: none;
    }
    .adaptive_rocket_wrapper{
        display: block;
        border: 2px solid #fbd43994;
        margin: 15px;
        border-radius: 44px;
        padding: 10px 10px;
        }
        .rocket_text_container{
            max-width: 200px;
            margin: 0;
            justify-content: center;
        }
        .rocket_sub_text{
            break-after: auto;
        }
        .rocket_container{
            margin: 0;
            align-items: center;
            height: auto;
            margin-bottom: 10px;
            justify-content: center;
        }
        .adaptive_rocket_wrapper{
            max-width: 600px;
            margin: 20px auto;
        }
}

@media screen and (max-width:425px) and (min-width:321px) {
    progress{
        width: 370px;
    }

    .graphic_left_inner{
        justify-content: center;
    }
    .container{
      padding: 20px;
    }
    .graphic_img{
        max-width: 180px;
    }
    .graphic_left_inner{
        margin: 0 auto;
    }
    .graphic{
        flex-direction: column;
        margin-top: 0;
    }
    .graphic_display{
        max-width: 170px;
        padding: 0;
        margin: 15px 0;
    }
    .info_wrapper{
       background: none;
    }
    .rockets_wrapper{
        display: none;
    }
    .adaptive_rocket_wrapper{
        display: block;
        border: 2px solid #fbd43994;
        margin: 15px;
        border-radius: 44px;
        padding: 10px 10px;
        }
        .rocket_text_container{
            max-width: 200px;
            margin: 0;
        }
        .rocket_sub_text{
            break-after: auto;
        }
        .rocket_container{
            margin: 0;
            align-items: center;
            height: auto;
            margin-bottom: 10px;
        }
        .rocket_icon{
            margin: 0;
            max-width: 100px;
        }
        .info_wrapper{
            margin: 0;
        }
        .info_circle{
            border: 2px solid #FBD539;
        }
}