.profile {
  max-width: 800px;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
}
.components_container {
  max-width: 800px;
  margin: 0 auto;
}

.s_item {
  max-width: 267px;
  margin: 0 auto;
}

.item_img {
  margin: 0 auto;
  max-width: 170px;
}
.username {
  color: #fff;
  text-align: center;
  margin-top: 0;
  font-weight: bold;
}
.lineplace_wrapper {
  border: 2px solid #c9ab5d;
  border-radius: 44px;
  background-color: rgba(255, 255, 255, 0.067);
}
.line_title {
  color: #fff;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
}
.line_text {
  color: #c9ab5d;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.9rem;
}
/* SLIDER */
/* userData */
.user_data {
  margin-top: 40px;
}
.user_data_wrapper {
  display: flex;
  background-color: #132f2e;
  border-radius: 15px;
  border: 2px solid #c9ab5d;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.user_data_container {
  background-color: #132f2e;
  border-radius: 15px;
  border: 2px solid #c9ab5d;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.pp_img {
  width: 250px;
  height: 250px;
  margin: auto 0;
}
.user_data_info {
  margin-left: 60px;
}
.user_data_info_title {
  color: #c9ab5d;
  font-size: 1.1rem;
}
.user_data_info_text {
  border-bottom: 2px dashed rgba(253, 213, 54, 0.468);
  color: #fff;
  font-size: 1.1rem;
  word-break: break-all;
}
/* ********* */
/* Personal Data */
.option_text_container {
  width: 100%;
  text-align: left;
  margin-left: 40px;
  display: flex;
  align-items: center;
}
.persona_data_option_title {
  max-width: 150px;
  width: 100%;
  font-size: 1.2rem;
  margin: 10px 0;
  color: #c9ab5d;
}
.copyButton {
  border: none;
  color: #000;
  padding: 5px;
  width: 150px;
  background-color: #c9ab5d;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .ref_wrapper .persona_data_option_title {
    display: none !important;
  }
  .ref_wrapper .persona_data_option_text {
    width: 100%;
    text-align: center !important;
  }
  .option_text_container {
    display: block;
  }

  .copyButton {
    display: flex;
    justify-content: center;
  }
  .refferal_link {
    text-align: center;
  }
}
.notification {
  font-size: 0.8rem;
  margin: 10px;
  color: #fff;
  white-space: nowrap;
}
.personal_data_option_text {
  font-size: 1.2rem;
  margin: 10px;
  color: #fff;
}
.team_button {
  border: none;
  color: #000;
  padding: 10px 15px;
  width: 150px;
  background-color: #c9ab5d;
  border-radius: 44px;
  margin: 0 auto;
  transition: 0.1s linear;
  font-weight: 700;
  font-size: 1rem;
}
.team_button:hover {
  cursor: pointer;
  box-shadow: 1px 1px 5px #c9ab5d98;
}
.team_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.personal_data_option_item {
  display: flex;
  border-bottom: 2px dashed rgba(253, 213, 54, 0.468);
}
.personal_data_option_item:last-child {
  display: flex;
  border-bottom: none;
}
.personal_data {
  margin-top: 40px;
}
/* ************* */
/* My team */
.my_team a {
  text-decoration: none;
}
.my_team {
  margin-top: 80px;
}
.my_team_info_option_title {
  color: #fff;
}
.my_team_options {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}
.my_team_info_option_text {
  color: #c9ab5d;
  margin-left: 15px;
}
.slider_place {
  max-width: 600px;
  margin: 0 auto;
}
.slider_text {
  color: #fff;
}
.slider_place_adapt {
  display: none;
}
/* ******* */
@media screen and (max-width: 320px) {
  .MetaMask_wrapper {
    width: 300px;
    word-break: break-all;
  }
  .line_text {
    font-size: 0.7rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 530px) {
  .refferal_link {
    white-space: nowrap;
  }
}
@media screen and (max-width: 425px) and (min-width: 321px) {
  .MetaMask_wrapper {
    width: 300px;
    word-break: break-all;
  }
}
@media screen and (max-width: 1024px) {
  .user_data_wrapper {
    display: flex;
    flex-direction: column;
  }
  .slider_place {
    display: none;
  }
  .slider_place_adapt {
    display: block;
  }
  .s_item {
    margin: 0 auto;
    max-width: 227px;
  }
  .user_data_info {
    margin: 0;
  }
  .profile {
    padding: 0 20px;
  }
  .pp_img {
    margin: 0 auto;
  }
  .pp_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .persona_data_option_title {
    max-width: 120px;
  }
  .option_text_container {
    margin-left: 0px;
  }
}
