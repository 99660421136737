.side {
  margin-top: 0;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  color: #c9ab5d;
}

.slider_wrapper {
  margin: 0 auto;
  color: #fff;
  margin-bottom: 50px;
  background-size: cover;
  border-radius: 44px;
}
.slider_count_wrapper{
  display:flex;
  width:100%;
  justify-content: space-between;

}
.slider_count_wrapper h2{
  margin-top:0px;
  margin-bottom:0px;
  font-size:18px;
}
.slider {
  margin: 0 auto;
  border: 2px solid #c9ab5d;
  border-radius: 15px;
  background-color: #132f2e;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.slide {
  height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #183b3a;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.slider_nav_item {
  border-radius: 44px;
  border: 2px solid #c9ab5d;
  text-align: center;
}
.package {
  color: #fff;
  text-align: center;
  margin: 2px;
}

.price {
  color: #c9ab5d;
  font-weight: bold;
  margin: 2px;
  text-align: center;
  font-size:20px;
}
.line_place {
  text-align: center;
  margin: 2px;
}
.slide_info {
  width: 200px;
  margin: 0 auto;
}
.text {
  margin-top: 0;
  text-align: center;
}
.slider_nav {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
}
.slider_place {
  height: 300px;
}
.userIMG {
  max-width: 150px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom:10px;
}
.slider_adapt {
  display: none;
  border: 1px solid #c9ab5d;
  border-radius: 15px;
  background-color: #183b3a;
}
.slider_wrapper_adapt {
  display: none;
}

@media screen and (max-width: 805px) {
  .slider_adapt {
    max-width: 260px;
    min-width: 259px;
    display: block;
  }
  .slider_wrapper_adapt {
    display: block;
    margin-top: 20px;
    color: #fff;
  }
  .slider_wrapper {
    display: none;
  }
  .userIMG {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .text {
    margin-bottom: 10px;
  }
  .slider {
    display: none;
  }
  .slider_inner {
    display: flex;
    justify-content: center;
  }
  .slide {
    max-width: 260px;
    margin: 0 auto;
  }
}